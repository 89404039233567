import { Component } from '@angular/core';
import { CardComponent } from './components/card/card.component';

@Component({
    selector: '[app-seccion-indicadores]',
    standalone: true,
    imports: [CardComponent],
    templateUrl: './seccion-indicadores.component.html',
    styleUrl: './seccion-indicadores.component.scss',
})
export class SeccionIndicadoresComponent {}
