import { Component, Input } from '@angular/core';
import { CardContainerComponent } from "../../../card-container/card-container.component";

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CardContainerComponent],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
})
export class CardComponent {
  @Input({ required: true }) cardTitleNumber!: string;
  @Input({ required: true }) cardTitleText!: string;
}
