
<app-card-container>
    <div class="parte-superior">
        <div class="img-container">
            <img src="/img/card-logo.png" alt="" />
        </div>
        <div>
            <h1 class="card-title">{{ cardTitleNumber }}</h1>
            <h2 class="card-sub-title _45">{{ cardTitleText }}</h2>
        </div>
    </div>
    <div class="card-contenido">
        <ng-content></ng-content>
    </div>
</app-card-container>
