<div class="wrapper">
    <app-card [cardTitleNumber]="'13+'" [cardTitleText]="'años'">
        <p>
            Con amplia experiencia impulsando proyectos. Cada uno<span
                class="strong"
            >
                con una solución a medida.
            </span>
        </p>
    </app-card>
    <app-card
        [cardTitleNumber]="'100%'"
        [cardTitleText]="'efectividad'"
    >
        <p>
            Cada organización que transitó
            <span class="strong"> el método Kainet completo </span>
            logró implementar su producto digital.
        </p>
    </app-card>
    <app-card [cardTitleNumber]="'50+'" [cardTitleText]="'proyectos'">
        <p>
            <span class="strong"> Transformamos exitosamente </span>
            organizaciones de distintos rubros y tamaños.
        </p>
    </app-card>
</div>